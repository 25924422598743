/**
 * This contains all of the chartJS config files for each type of chart (e.g. bar charts, pie charts) used in the app.
 */

import { 
    getValueFromObject, getGroupValue, distinctSet, orderSet, currentYear,
    getDisplayValue, testValue, getAutoBandSpecs, getAutoBands, getAutoLabels 
} from '@/functions/utils.js';
import { applyFilters } from '@/functions/filters.js';
import variables from '@/assets/styles/colors.scss';
import { loginAnalyticsBar, loginAnalyticsLine } from '@/functions/loginAnalytics';

export const colors = [
    '#2e86c1', '#d4ac0d', '#884ea0', '#17a589', '#d68910',  
    '#229954', '#28b463', '#2471a3', '#ca6f1e', '#ba4a00', 
    '#a93226', '#bc4335', '#138d75', '#5499c7', '#5dade2', 
    '#48c9b0', '#45b39d', '#7d3c98', '#aaaaaa', '#666666'
];

const getRecordCount = ({data, field, value}) => {
    return data.filter(element => {
        const val = getValueFromObject(element, field.key);
        return testValue({field, value: val, category: value});
    }).length;
};

const getTitle = str => ({
    display: true,
    text: str || '',
    font: {
        size: 24
    }
});

const charts = {};

const getLabels = ({chartConfig, chartData}) => {
    if (chartConfig.primaryField.type === 'numeric') {
        const myBands = chartConfig.primaryField.bands ?
            chartConfig.primaryField.bands.map(band => band.label):
            getAutoLabels({chartData, fieldKey: chartConfig.primaryField.key});

        return myBands.concat('No data');
    }
    return distinctSet({
        fieldKey: chartConfig.primaryField.key, 
        data: chartData
    }).sort((a, b) => orderSet({
        a, b, field: chartConfig.primaryField
    }));
};

const getDisplayLabels = ({chartConfig, labels}) => {
    if (chartConfig.primaryField.bands) {
        return labels;
    }
    return labels.map(element => getDisplayValue({field: chartConfig.primaryField, value: element}))
};

const getGroups = ({chartConfig, chartData}) => {

    if (chartConfig.secondaryField.type === 'numeric') {
        const myBands = chartConfig.secondaryField.bands ?
            chartConfig.secondaryField.bands.map(band => band.label):
            getAutoLabels({chartData, fieldKey: chartConfig.secondaryField.key});

        const result = myBands
            .concat('No data')
            .map(band => ({value: band, label: band}));

        return result;
    }

    return distinctSet({
        fieldKey: chartConfig.secondaryField.key, 
        data: chartData
    }).map(group => ({
        label: getDisplayValue({
            value: group, 
            field: chartConfig.secondaryField, 
        }),
        value: group
    })).sort((a, b) => orderSet({
        a: a.value, 
        b: b.value, 
        field: chartConfig.secondaryField
    }));    
};

/**
 * This function determines which colors will be used for the charts based off
 * the options set in the field configuration.
 * 
 * @param {object} field    field object we are determining colors for
 * @param {string} theme    applied theme (default, access, dark)
 * @param {arrary} options  field labels that we need colors for
 * 
 * @returns Array of Hex Codes like ['#2e86c1', '#d4ac0d']
 */
const getColors = ({field, theme, options=null}) => {
    if (field.bands) {
        return field.bands
            .map(band => variables[`${theme}${band.style}`])
            .concat(variables[`${theme}contrastLow`]);
    }

    //this is a special option set in the field configuration where the 
    //colors for a chart can be different than the colors shown in the student lists.
    //example is 'Active Plan'
    if (field.colorGroupingOptions) {
        return options.map((option, i) => 
            [null, 'No data'].some(e => e === option) ? 
            variables[`${theme}contrastLow`] : 
            (
                field.colorGroupingOptions?.[option]?.style ? 
                variables[`${theme}${field.colorGroupingOptions[option].style}`] : 
                colors[i]
            ));
    }

    if (field.options && options) {
        return options.map((option, i) => 
            [null, 'No data'].some(e => e === option) ?
            variables[`${theme}contrastLow`] :
            (
                field.options?.[option]?.style ?
                variables[`${theme}${field.options[option].style}`] :
                colors[i]
            ));
    }
    
    //TECH DEBT: idk if this is really tech debt or just needs a comment but putting a note here for further contemplation. 
    // This 'colors' variable is defined on the global scope for some reason. 
    // Makes this hard to read or know where this variable is coming from. Everytime I look at this I get confused of where it is defined.
    return colors;
};

const getNumericData = ({chartData, chartConfig, theme}) => {
    const bands = chartConfig.primaryField.bands || getAutoBands({chartData, fieldKey: chartConfig.primaryField.key});
    const nullCount = chartData.filter(element => getValueFromObject(element, chartConfig.primaryField.key) === null).length
    const result = bands.map(band => chartData
        .filter(element => {
            const val = getValueFromObject(element, chartConfig.primaryField.key);
            if (val === null) return false;
            //update logic here to match band calculations
            return val <= band.high && val >= band.low;
        })
        .length
    ).concat(nullCount);

    const totalRecords = chartData.length;
    if (chartConfig.showAsPercentage) {
        return result.map(element => (element * 100 / totalRecords).toFixed(0));
    }
    return result;
};

charts.analyticsPie = ({chartData, chartConfig, theme}) => {
    if (!chartConfig.primaryField) return {};

    chartData = applyFilters({data: chartData, filters: chartConfig.filters});

    const labels = getLabels({chartData, chartConfig});

    const dataPoints = chartConfig.primaryField.type === 'numeric' ?
        getNumericData({chartData, chartConfig}):
        labels.map(category => {
            return chartData.filter(element => getValueFromObject(element, chartConfig.primaryField.key) == category).length;
        });

    const backgroundColor = getColors({
        field: chartConfig.primaryField, 
        options: labels,
        theme
    });

    const myLabels = labels
        .map(label => getDisplayValue({field: chartConfig.primaryField, value: label}))

    const data = {
        labels: myLabels,
        datasets: [{
            label: '',
            data: dataPoints,
            backgroundColor,
            hoverOffset: 4
        }]
    };

    return {
        type: 'pie',
        data: data,
        options: {
            plugins: {
                legend: {
                    position: chartConfig.legendPosition || 'bottom'
                },
                title: getTitle(chartConfig.title),
                datalabels: {
                    display: true,
                    formatter(value, context) {
                        if (value === 0) return '';
                        const percentage = chartConfig.showAsPercentage && chartConfig.primaryField.type === "numeric" ? value : Math.round((value / chartData.length) * 100);

                        return getNumberFormat({
                            count: value, 
                            percentage, 
                            selectedFormatOption: chartConfig.selectedFormatOption
                        });
                    }
                }
            },
            onClick: function (e, arr) {
                const filters = [{
                    key: chartConfig.primaryField.key,
                    value: labels[arr[0].index]
                }];

                chartConfig.onClick({filters});
            }
        }
    };
};

charts.autoHistogram = ({chartData, chartConfig, theme}) => {
    if (!chartConfig.primaryField) return {};

    chartData = applyFilters({data: chartData, filters: chartConfig.filters});

    const [min, barWidth, barCount] = getAutoBandSpecs({chartData, fieldKey: chartConfig.primaryField.key});

    if (!Number.isFinite(barCount)) return {};

    let labels = getLabels({chartData, chartConfig});

    let dataPoints = chartConfig.primaryField.type === 'numeric' ?
        getNumericData({chartData, chartConfig}):
        labels.map(category => {
            return chartData.filter(element => getValueFromObject(element, chartConfig.primaryField.key) === category).length;
        });

    // get rid of 'no data' bar if empty
    if (dataPoints[dataPoints.length - 1] === 0) {
        labels.pop();
        dataPoints.pop();
    }
    
    const backgroundColor = getColors({
        field: chartConfig.primaryField, 
        options: labels,
        theme
    });

    const data = {
        labels,
        datasets: [{
            label: '',
            data: dataPoints,
            backgroundColor,
            hoverOffset: 4
        }]
    };

    return {
        type: 'bar',
        data: data,
        options: {
            plugins: {
                legend: {
                    display: false,
                },
                title: getTitle(chartConfig.title),
                datalabels: {
                    display: true,
                    formatter(value, context) {
                        if (value === 0) return '';

                        const percentage = Math.round((value / chartData.length) * 100);
                        return getNumberFormat({
                            count: Math.round(value), 
                            percentage, 
                            selectedFormatOption: chartConfig.selectedFormatOption
                        });
                    }
                }
            },
            onClick: function (e, arr) {
                // const filters = [{
                //     key: chartConfig.primaryField.key,
                //     value: labels[arr[0]?.index]
                // }];

                // chartConfig.onClick({filters});
            }
        }
    };
};

charts.singleBand = ({chartData, chartConfig, theme}) => {
    if (!chartConfig.primaryField) return {};

    chartData = applyFilters({data: chartData, filters: chartConfig.filters});

    const recordCount = chartData.length;

    const labels = getLabels({chartData, chartConfig}); 

    const rawDataPoints = chartConfig.primaryField.type === 'numeric' ?
        getNumericData({chartData, chartConfig}):
        labels.map(category => {
            return chartData.filter(element => getValueFromObject(element, chartConfig.primaryField.key) === category).length;
        });


    const dataPoints = rawDataPoints.map(num => num * 100 / recordCount);

    const myLabels = labels
        .map(label => getDisplayValue({field: chartConfig.primaryField, value: label}))

    const backgroundColor = getColors({
        field: chartConfig.primaryField,
        options: myLabels,
        theme
    });

    const data = {
        labels: [''],
        datasets: myLabels.map((element, i) => ({
            label: element,
            data: [dataPoints[i]],
            backgroundColor: backgroundColor[i],
        }))
    };
        
    return {
        type: 'bar',
        data: data,
        options: {
            scales: {
                y: {
                    grid: {
                        display: chartConfig.displayTicks
                    },
                    stacked: true,
                    display: chartConfig.displayTicks,
                },
                x: {
                    ticks: {
                        display: chartConfig.displayTicks,
                    },
                    grid: {
                        display: chartConfig.displayTicks
                    },
                    stacked: true,
                    display: chartConfig.displayTicks,
                    max: 100
                }
            },
            aspectRatio: 6,
            indexAxis: 'y',
            responsive: true,
            plugins: {
                legend: {
                    display: chartConfig.displayTicks,
                    position: 'top'
                },
                title: '',
                datalabels: {
                    formatter(value, context) {
                        if (value == 0) return '';
                        const count = Math.round(value * recordCount / 100);
                        return `${count} (${Math.round(value)}%)`;
                    }
                }
            },
            onClick: function (e, arr) {
                const filters = [{
                    key: chartConfig.primaryField.key,
                    value: labels[arr[0].datasetIndex]
                }];
                chartConfig.onClick({filters});
            }
        }
    };
};

charts.matrix = ({chartData, chartConfig, theme}) => {

    const bgColors = [`${theme}warning`, `${theme}info`, `${theme}gradeB`, `${theme}success`];
    const myColors = [`${theme}bg`, `${theme}contrastFull`, `${theme}contrastFull`, `${theme}bg`];

    const datasets = chartData.map((element, i) => ({
        label: chartConfig.labels[i],
        data: [element],
        backgroundColor: variables[bgColors[i]],
        datalabels: {
            color: variables[myColors[i]],
        }
    }));

    const data = {
        labels: [''],
        datasets
    };

    const max = chartData.reduce((a, b) => a + b, 0); // sum of records
                
    return {
        type: 'bar',
        data,
        options: {
            scales: {
                y: {
                    stacked: true,
                },
                x: {
                    stacked: true,
                    max
                }
            },
            aspectRatio: 8,
            indexAxis: 'y',
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
            },
        }
    };
};

const getNumberFormat = ({count, percentage, selectedFormatOption='Show count'}) => [
    {
        mode: 'Show count',
        str: `${count}`
    },
    {
        mode: 'Show percentage',
        str: `${percentage}%`
    },
    {
        mode: 'Show count and percentage',
        str: `${count} (${percentage}%)`
    }
].find(element => element.mode == selectedFormatOption).str;


charts.grouped = ({chartData, chartConfig, theme}) => {

    if (!chartConfig.primaryField || !chartConfig.secondaryField) return {};

    const stacked = chartConfig.stacked;

    chartData = applyFilters({data: chartData, filters: chartConfig.filters});

    const labels = getLabels({chartData, chartConfig});
    const groups = getGroups({chartData, chartConfig});

    let emptyCategories = [];

    /**
     * myCounts is used for counting the number of students in each category. In the percentage view of the
     * chart, the underlying data values need to equal the percentage of students for each value. However,
     * the labels still need to show the count of students, so we need to keep the count in a separate variable.
     */
    const myCounts = [];

    const source = labels.map(category => {
        const result = {};
        const counts = {};

        const totalRecords = getRecordCount({
            data: chartData, 
            field: chartConfig.primaryField, 
            value: category
        });

        groups.forEach(group => {

            const myRecords = chartData.filter(element => {
                const primaryValue = getValueFromObject(element, chartConfig.primaryField.key);
                const secondaryValue = getValueFromObject(element, chartConfig.secondaryField.key);

                return testValue({field: chartConfig.primaryField, value: primaryValue, category}) && 
                    testValue({field: chartConfig.secondaryField, value: secondaryValue, category: group.value});
            });

            counts[group.label] = myRecords.length;

            result[group.label] = chartConfig.showAsPercentage?
                myRecords.length * 100 / totalRecords:
                myRecords.length;
        });

        if (!Object.values(result).some(element => element > 0)) {
            emptyCategories.push(category);
            return null;
        }

        myCounts.push(counts);
        return result;
    }).filter(e => e !== null);

    const nonEmptyGroups = groups.filter(group => {
        // only filter out 'No data' as an empty group
        if (group.label !== 'No data') return true;
        return source
            .map(obj => obj[group.label])
            .some(value => value > 0)
    });

    const nonEmptyLabels = labels.filter(l => !emptyCategories.includes(l));

    // Colors

    const backgroundColor = getColors({
        field: chartConfig.secondaryField, 
        options: nonEmptyGroups.map(e => e.value),
        theme
    });

    const datasets = nonEmptyGroups.map((group, i) => ({
        label: group.label,
        data: source.map(e => e[group.label]),
        backgroundColor: backgroundColor[i]
    }));

    const data = {
        labels: getDisplayLabels({chartConfig, labels: nonEmptyLabels}),
        datasets
    };

    return {
        type: 'bar',
        data,
        options: {
            scales: {
                y: {
                    stacked,
                    title: {
                        display: !stacked,
                        text: chartConfig.showAsPercentage ? '% of students' : 'Student count',
                    }
                },
                x: {
                    stacked,
                    max: chartConfig.showAsPercentage ? 100 : undefined,
                    title: {
                        display: stacked,
                        text: chartConfig.showAsPercentage ? '% of students' : 'Student count',
                    }
                }
            },
            indexAxis: stacked ? 'y' : 'x',
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: getTitle(chartConfig.title),
                datalabels: {
                    display: true,
                    formatter(value, context) {
                        if (value === 0) return '';
                        const inputValueIsPercentage = ['Show percentage', 'Show count and percentage'].some(element => element === chartConfig.selectedFormatOption) && chartConfig.showAsPercentage;

                        const percentage = inputValueIsPercentage ?
                            Math.round(value):
                            Math.round((value / chartData.length) * 100);

                        const count = inputValueIsPercentage ? 
                            myCounts[context.dataIndex][context.dataset.label]:
                            Math.round(value);

                        return getNumberFormat({
                            count, 
                            percentage, 
                            selectedFormatOption: chartConfig.selectedFormatOption
                        });
                    }
                }
            },
            onClick: function (e, arr) {
                if (!arr[0]) {
                    console.error('undefined element in array', arr);
                    return;
                }
                const filters = [
                    {
                        key: chartConfig.primaryField.key,
                        value: nonEmptyLabels[arr[0].index]
                    },
                    {
                        key: chartConfig.secondaryField.key,
                        value: getGroupValue({
                            field: chartConfig.secondaryField,
                            value: datasets[arr[0].datasetIndex].label
                        })
                    },
                ];
                chartConfig.onClick({filters});
            }
        }
    };
};

charts.simpleBar = ({chartData, chartConfig, theme}) => {

    if (!chartConfig.primaryField) return {};

    chartData = applyFilters({data: chartData, filters: chartConfig.filters});

    const labels = getLabels({chartData, chartConfig}).sort((a, b) => {
        const _a = getDisplayValue({field: chartConfig.primaryField, value: a});
        const _b = getDisplayValue({field: chartConfig.primaryField, value: b});
        return orderSet({a: _a, b: _b, field: chartConfig.primaryField})
    });

    const dataPoints = chartConfig.primaryField.type === 'numeric' ?
        getNumericData({chartData, chartConfig}):
        labels.map(category => {
            return chartData.filter(element => getValueFromObject(element, chartConfig.primaryField.key) == category).length;
        });

    const backgroundColor = getColors({
        field: chartConfig.primaryField, 
        options: labels,
        theme
    });
    
    const myLabels = labels
        .map(label => getDisplayValue({field: chartConfig.primaryField, value: label}));

    const data = {
        labels: myLabels,
        datasets: [{
            label: '',
            data: dataPoints,
            backgroundColor,
            hoverOffset: 4
        }]
    };

    return {
        type: 'bar',
        data: data,
        options: {
            scales: {
                y: {
                    title: {
                        display: true,
                        text: 'Student count',
                    }
                },
            },
            plugins: {
                legend: {
                    display: false
                },
                title: getTitle(chartConfig.title),
                datalabels: {
                    display: true,
                    formatter(value, context) {
                        if (value == 0) return '';

                        const percentage = Math.round((value / chartData.length) * 100);

                        return getNumberFormat({
                            count: value, 
                            percentage, 
                            selectedFormatOption: chartConfig.selectedFormatOption
                        });
                    }
                }
            },
            onClick: function (e, arr) {
                const filters = [{
                    key: chartConfig.primaryField.key,
                    value: labels[arr[0].index]
                }];

                chartConfig.onClick({filters});
            }
        }
    };
};

charts.onTrackLine = ({chartData, chartConfig, theme}) => {

    const labels = ['Q1', 'Q2', 'Q3', 'Q4'];
    const years = new Array(4).fill('').map((e, i) => `${currentYear - 4 + i}-${currentYear - 2003 + i}`);

    const data = {
        labels,
        datasets: Object.values(chartData).map((entry, i) => ({
            label: years[i],
            data: labels.map(label => entry[label]),
            backgroundColor: colors[i],
            borderColor: colors[i]
        }))
    };

    return {
        type: 'line',
        data,
        options: {
            scales: {
                y: {
                    title: {
                        display: true,
                        text: '% on-track',
                    }
                },
            },
            plugins: {
                legend: {
                    position: 'top'
                },
                title: 'Grade 9 On-Track Rate by Quarter',
                datalabels: {
                    display: false,
                    formatter (value, context) {
                        return `${value}%`;
                    }
                }
            },
        }
    };
};

charts.onTrackBar = ({chartData, chartConfig, theme}) => {

    const labels = new Array(4).fill('').map((e, i) => `${currentYear - 4 + i}-${currentYear - 2003 + i}`);

    const data = {
        labels,
        datasets: [{
            label: '',
            data: [87, 88, 56, 75],
            backgroundColor: colors,
            hoverOffset: 4
        }]
    };

    return {
        type: 'bar',
        data,
        options: {
            scales: {
                y: {
                    title: {
                        display: true,
                        text: '% on-track',
                    }
                },
            },
            plugins: {
                legend: {
                    display: false
                },
                title: 'EOY Grade 9 On-Track Rate',
                datalabels: {
                    display: true,
                    formatter (value, context) {
                        return `${value}%`;
                    }
                }
            },
        }
    };
};

charts.loginAnalyticsBar = loginAnalyticsBar;
charts.loginAnalyticsLine = loginAnalyticsLine;

export const getChart = ({chartData, chartConfig, theme}) => charts[chartConfig.type]({chartData, chartConfig, theme});

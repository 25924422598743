/**
 * This file contains the functions for creating the chartJS config files to produce the login analytics charts.
 */

import { roles } from '@/apps/roles';
import { colors } from '@/functions/charts'
import { beautifyDate } from '@/functions/utils'
import variables from '@/assets/styles/colors.scss';

const getDataSet = ({chartData, chartConfig}) => {
    if (!chartData.length) return [];
    return chartConfig.school === 'Network' ? 
        chartData[chartConfig.weekIndex].networkSummary:
        chartData[chartConfig.weekIndex].orgSummaries[chartConfig.schoolKey];
};

const getAvailableRoles = ({myData, _roles=roles}) => _roles
    .concat({displayName: 'Total', key: 'total'})
    .filter(role => !!myData?.[role.key]?.userCount);

const getPercent = ({role, band, data}) => data[role.key][band] * 100 / data[role.key].userCount;

const getBands = ({theme, variables}) => [
    { key: 'noLogins', label: 'No logins', backgroundColor: variables[`${theme}warning`] },
    { key: 'oneOrTwoLogins', label: '1-2 logins', backgroundColor: variables[`${theme}info`] },
    { key: 'threeOrMoreLogins', label: '3+ logins', backgroundColor: variables[`${theme}success`] },
];

const getMultiWeek = ({role, data, chartConfig}) => data
    .map(element => chartConfig.school === 'Network' ? 
        element.networkSummary:
        element.orgSummaries[chartConfig.schoolKey]
    )
    .map(element => {
        return element[role.key] ? 
            element[role.key].threeOrMoreLogins * 100 / element[role.key].userCount:
            0;
    });

export const exportedForTesting = {
    getDataSet, getAvailableRoles, getPercent, getBands, getMultiWeek
};

export const loginAnalyticsBar = ({chartData, chartConfig, theme}) => {

    const myData = getDataSet({chartData, chartConfig});
    const myRoles = getAvailableRoles({myData});
    const bands = getBands({theme, variables});

    const labels = myRoles.map(role => role.displayName);
    const datasets = bands.map(band => ({
        label: band.label,
        data: myRoles.map(role => getPercent({role, band: band.key, data: myData})),
        backgroundColor: band.backgroundColor,
    }));

    const myCounts = myRoles.map(role => bands.map(band => myData[role.key][band.key]));

    return {
        type: 'bar',
        data: {labels, datasets},
        options: {
            scales: {
                y: {
                    stacked: true,
                },
                x: {
                    stacked: true,
                    max: 100,
                    title: {
                        display: true,
                        text: '% of users',
                    }
                }
            },            
            indexAxis: 'y',
            responsive: true,
            plugins: {
                legend: {
                    position: 'right',
                },
                title: {
                    display: true,
                    text: `${chartConfig.school} logins week starting ${beautifyDate(chartConfig.week)}`
                },
                datalabels: {
                    display: true,
                    formatter(value, context) {
                        if (value === 0) return '';

                        const percentage = Math.round(value);
                        const count = myCounts[context.dataIndex][context.datasetIndex];

                        return `${count} (${percentage}%)`;
                    }
                }            
            }
        },
    };
};

export const loginAnalyticsLine = ({chartData, chartConfig, theme}) => {

    const dataInRange = chartData
        .filter((e, i) => i >= chartConfig.startWeekIndex && i <= chartConfig.endWeekIndex);

    const labels = dataInRange.map(element => beautifyDate(element.weekStartingDate));
    const myData = getDataSet({chartConfig, chartData});
    const myRoles = getAvailableRoles({myData});

    const datasets = myRoles.map((role, i) => ({
        label: role.displayName,
        data: getMultiWeek({role, data: dataInRange, chartConfig}),
        backgroundColor: colors[i],
        borderColor: colors[i]
    }));

    return {
        type: 'line',
        data: {labels, datasets},
        options: {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: '% of Users meeting Login KPI by Role'
                },
                datalabels: {
                    display: false,
                }            
            }
        },
    };
};
